import React from "react"
import Button from "@mui/material/Button";

const OfficeComponent = ({prompt, onResponse, responses}) => {
    prompt.config.options.sort((a,b) => parseInt(a.time) < parseInt(b.time) ? -1 : 1)
    return <div>
        {prompt.config.options?.map(({id, text, time}, index) => {
            const color = time > 45 ? 'red' : time > 30 ? 'darkorange' : '#007749'
            return <Button
                key={index}
                variant="outlined"
                onClick={onResponse}
                sx={{
                    width: '100%',
                    marginTop: '1em',
                    borderColor: color,
                    color: color,
                    whiteSpace: 'pre'
                }}>
                <b>{text}</b> ({time}min)
            </Button>
        })}
    </div>
}

export default OfficeComponent