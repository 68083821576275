import React from "react";
import {ThemeProvider} from "@mui/material/styles";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Authentication from "./screens/Authentication";
import SelfService from "./screens/SelfService";
import theme from "./theme";

const App = () => {
    const [code, setCode] = React.useState(null)

    console.log('code', code)

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Authentication setCode={setCode}/>
                    </Route>
                    <Route exact path="/login/:code">
                        <Authentication setCode={setCode}/>
                    </Route>
                    <Route path="/">
                        <SelfService code={code}/>
                    </Route>
                    <Redirect from={"/"} to={"/login"}/>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App;