import {setCustomComponents} from "@a2d24/conversation-ui";
import CheckboxComponent from './checkbox.js'
import LinkComponent from './link.js'
import OfficeComponent from "./office";


// This will add CheckboxComponent of type `custom_type` to the list of available types
const initCustomComponents = () => {
    setCustomComponents({checkbox: CheckboxComponent});
    setCustomComponents({link: LinkComponent});
    setCustomComponents({office: OfficeComponent});
}

export default initCustomComponents