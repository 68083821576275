import React, {useEffect, useState} from "react";
import {useHistory, useParams, Redirect} from "react-router-dom";
import ConversationUI from "@a2d24/conversation-ui";
import {Backdrop, CircularProgress} from '@mui/material';
import workflows from "../workflows/workflows";
import logo from "../assets/logo.png";
import useStyles from "./styles";
import axios from "axios";
import ErrorPopup from "../components/ErrorPopup";
import GetStartedScreen from "./GetStarted";


const API_URL = process.env.REACT_APP_API_ENDPOINT;
const arrayFields = [
    'allergies',
    'medical_conditions',
    'medical_condition_dates',
    'surgical_procedures',
    'surgical_procedure_dates',
    'chronic_medications',
    'family_medical_conditions',
    'vaccines',
    'vaccine_dates'
];

const SelfService = ({code}) => {
    console.log('SelfService')
    const classes = useStyles();
    const history = useHistory();

    let results = {}

    const [loading, setLoading] = useState(false);
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [workflow, setWorkflow] = useState(false);

    function clearEmpties(o) {
        for (const k in o) {
            if (!o[k] || typeof o[k] !== "object") {
                continue // If null or not an object, skip to the next iteration
            }

            // The property is an object
            if (Object.keys(o[k]).length === 0) {
                delete o[k]; // The object had no properties, so delete that property
            }
        }
    }

    useEffect(() => {
        if (code !== 'a2d24demo') history.push('/login')
    }, [code]);


    if (loading) return <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
    >

        <CircularProgress color="inherit"/>
    </Backdrop>

    const map_results_to_payload = (results) => {
        let prompt_map = {}
        workflows[workflow]?.prompts?.forEach((item) => {
            prompt_map[item.id] = item.section
        })
        let payload = {}
        workflows[workflow]?.prompts?.forEach((item) => {
            if (!(item.section in payload)) {
                payload[item.section] = {}
            }
            if (results[item.id]) payload[item.section][item.id] = results[item.id];

        })
        clearEmpties(payload)
        payload.self_service_type = workflows[workflow].type
        return payload
    }

    const onComplete = async () => {
        const payload = map_results_to_payload(results);
        const referenceNumber = await submit(payload);
        if (referenceNumber) {
            const patientName = results.patient_first_name;
            const redirectPath = patientName ? `/${referenceNumber}?name=${patientName}` : `/${referenceNumber}`;
            history.push(redirectPath);
        }
        // TODO: handle error
        else {
            setLoading(false)
            setOpenErrorPopup(true)
            setWorkflow(false)
        }

    }

    const submit = async (payload) => {
        setLoading(true)
        try {
            const response = await axios.post(
                `${API_URL}/SelfService/create_submission`,
                {self_service_payload: payload}
            )
            setLoading(false)
            return response.data

        } catch (error) {
            return false;

        }

    }

    const handleResponse = (response, promptId) => {
        if (response === undefined) {
            return;
        }
        if (arrayFields.indexOf(promptId) > -1) {
            if (Array.isArray(results[promptId])) {
                if (response) results[promptId].push(response);
            } else {
                if (response) results[promptId] = [response];
                else results[promptId] = [];
            }
        } else {
            results[promptId] = response;
        }
    }

    return (
        <div className={!workflow ? classes.home : classes.root}>
            <ErrorPopup isOpen={openErrorPopup} setIsOpen={setOpenErrorPopup}/>
            <GetStartedScreen show={!workflow} setWorkflow={setWorkflow}/>
            {workflow && <ConversationUI
                prompts={workflows[workflow]?.prompts}
                initialPrompt={workflows[workflow]?.initialPrompt}
                onComplete={onComplete}
                onResponse={handleResponse}
                logo={logo}
                messageProps={workflows[workflow]}
            />}
        </div>
    )
}

export default SelfService;