import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: '#02bf76',
            main: '#007749',
            dark: '#003822'
        },
    },
    shape: {
        borderRadius: "24px"
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

export default theme;