import {backdropStyles} from "./styles";
import password from "../assets/password.svg";
import Background from "../components/Background";
import React from "react";
import {useHistory, useParams} from "react-router-dom";


const AuthenticationScreen = ({setCode}) => {
    console.log('AuthenticationScreen')
    const styles = backdropStyles();
    const history = useHistory()
    const params = useParams()
    const [localCode, setLocalCode] = React.useState(null)

    if (params.code) {
        setCode(params.code)
        history.push(`/`)
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>Welcome to the future of citizen services</div>
                <div className={styles.subtitle}>Please provide a valid access code to log in
                </div>
                <img className={styles.people} alt="" src={password}/>
                    <input type="password" className={`${styles.button} ${styles.button_one}`} style={{
                        background: 'white',
                        color: 'black',
                        padding: '0 3rem',
                        border: '2px solid #007749',
                        margin: '0 0 0.5rem'
                    }} placeholder={"Access Code"} onInput={(ev) => setLocalCode(ev.target.value)}/>
                    <button className={`${styles.button} ${styles.button_two}`} style={{margin: '0.5rem 0 0'}}
                            onClick={() => {
                                setCode(localCode)
                                history.push(`/`)
                            }}>Login
                    </button>
            </div>
            <Background showAngled={false} className={styles.background}/>
        </div>
    )
}

export default AuthenticationScreen