import TermsAndConditions from "../components/TermsAndConditions";
import TermsContent from "./consts/TermsContent";
import { allergiesWithNone as allergyList } from "./consts/allergies";
import { medicalConditionsWithNone as medicalConditionsList } from "./consts/medicalConditions";
import { surgicalProceduresWithNone as surgicalProceduresList } from "./consts/surgicalProcedures";
import vaccinesList from "./consts/vaccines";
import { PROMPT_IDS } from "./consts/promptIds";
import {
    availableLanguages,
    languages
} from "./consts/languages";
import {homeAffairsOffices} from "./consts/homeAffairsOffices";
import {birthCertificateRequirements, documentRequirements} from "./consts/documentRequirements";
import {homeAffairsDocuments} from "./consts/homeAffairsDocuments";

const welcomeText = `Welcome!

I'm here to help you complete some key information ahead of your medical consultation.
`;

// export const initialPrompt = PROMPT_IDS.FOR_WHO;
export const initialPrompt = PROMPT_IDS.HA_SELECT_LANGUAGE;

export const userValidation = [
    {
        id: PROMPT_IDS.WELCOME,
        type: "option",
        section: "user_validation",
        config: {
            message: welcomeText,
            options: [{ id: "start", text: "Get started" }]
        },
        onResponse: (response) => PROMPT_IDS.FOR_WHO
    },
    {
        id: PROMPT_IDS.FOR_WHO,
        type: "option",
        section: "user_validation",
        config: {
            message: "Who are you completing this information for?",
            options: [
                { id: "myself", text: "For myself" },
                { id: "someone_else", text: "For someone else" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.ACCEPT_TERMS_CONDITIONS
    },
    {
        id: PROMPT_IDS.ACCEPT_TERMS_CONDITIONS,
        type: "option",
        section: "user_validation",
        config: {
            message: responses => {
                const isForMySelf = responses[PROMPT_IDS.FOR_WHO] === "myself";
                return <TermsAndConditions content={<TermsContent />} myself={isForMySelf} />
            },
            options: [
                { id: "accept", text: "I accept" },
                { id: "decline", text: "I don't accept" }
            ]
        },
        onResponse: (response, id, ctrl) => {
            if (response !== "accept") {
                ctrl.clearHistory();
                return PROMPT_IDS.WELCOME;
            } else {
                const { responses } = ctrl.state;
                const isForMySelf = responses[PROMPT_IDS.FOR_WHO] === "myself";
                return isForMySelf ? "is_eighteen" : PROMPT_IDS.PATIENT_FIRST_NAME;
            }
        }
    },

    {
        id: PROMPT_IDS.PATIENT_FIRST_NAME,
        type: "text",
        section: "user_validation",
        config: {
            message: "What is the patient's first name?"
        },
        onResponse: () => "is_eighteen"
    },
    {
        id: PROMPT_IDS.IS_EIGHTEEN,
        type: "option",
        section: "user_validation",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "To help us complete the right information for you, please confirm that you are 18 years or older?"
                } else {
                    const { patient_first_name } = responses;
                    return `To help us complete the right information, please confirm that ${patient_first_name} is 18 years or older?`
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.TRAVELLED_INTERNATIONALLY
    }
];

export const preVisitAssessment = [
    {
        id: PROMPT_IDS.TRAVELLED_INTERNATIONALLY,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you travelled internationally in the last 21 days?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} travelled internationally in the last 21 days?`
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response, id, ctrl) => {
            if (response === "yes") {
                const { responses } = ctrl.state;
                return responses[PROMPT_IDS.IS_EIGHTEEN] === "yes" ? PROMPT_IDS.IS_HEALTHCARE_WORKER : PROMPT_IDS.TRAVEL_REASON;
            } else {
                return PROMPT_IDS.TRAVELLING_RSA;
            }

        }
    },
    {
        id: PROMPT_IDS.IS_HEALTHCARE_WORKER,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;
                if (for_who === "myself") {
                    return "Are you a healthcare worker?";
                } else {
                    const { patient_first_name } = responses;
                    return `Is ${patient_first_name} a healthcare worker?`
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.HEALTHCARE_WORKER_TRAVELLING : PROMPT_IDS.TRAVEL_REASON
    },
    {
        id: PROMPT_IDS.HEALTHCARE_WORKER_TRAVELLING,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Were you travelling as a healthcare worker with the purpose of providing healthcare?";
                } else {
                    const { patient_first_name } = responses;
                    return `Was ${patient_first_name} travelling as a healthcare worker with the purpose of providing healthcare?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.IN_CONTACT_WITH_ILL_PERSON : PROMPT_IDS.TRAVEL_REASON
    },
    {
        id: PROMPT_IDS.TRAVEL_REASON,
        type: "text",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "What was the reason for your travel?";
                } else {
                    const { patient_first_name } = responses;
                    return `What was the reason for ${patient_first_name}'s travel?`;
                }
            }
        },
        onResponse: (response) => PROMPT_IDS.IN_CONTACT_WITH_ILL_PERSON
    },
    {
        id: PROMPT_IDS.IN_CONTACT_WITH_ILL_PERSON,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Were you in contact with anyone that was ill during your visit?";
                } else {
                    const { patient_first_name } = responses;
                    return `Was ${patient_first_name} in contact with anyone that was ill during the visit?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.ATTENDED_FUNERAL
    },
    {
        id: PROMPT_IDS.ATTENDED_FUNERAL,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Did you attend a funeral during your travel?";
                } else {
                    const { patient_first_name } = responses;
                    return `Did ${patient_first_name} attend a funeral during the travel?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.TRAVELLING_RSA
    },
    {
        id: PROMPT_IDS.TRAVELLING_RSA,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you travelled within SA in the last 14 to 21 days?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} travelled within SA in the last 14 to 21 days?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.HAS_FEVER : PROMPT_IDS.UPDATE_PROFILE_PHOTO
    },
    {
        id: PROMPT_IDS.HAS_FEVER,
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you have a fever?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} have a fever?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response) => response === "yes" ? "temperature" : "travelling_malaria"
    },
    {
        id: "temperature",
        type: "number",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please enter your temperature (Degrees Celsius)";
                } else {
                    const { patient_first_name } = responses;
                    return `Please enter ${patient_first_name}'s temperature (Degrees Celsius)`;
                }
            },
            min: 20,
            max: 50,
            optional: true
        },
        onResponse: (response) => "travelling_malaria"
    },
    {
        id: "travelling_malaria",
        type: "option",
        section: "pre_visit_assessment",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you travelled to a Malaria area in South Africa?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} travelled to a Malaria area in South Africa?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" },
                { id: "not_sure", text: "I'm not sure" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.UPDATE_PROFILE_PHOTO
    }
];

export const profilePhoto = [
    {
        id: PROMPT_IDS.UPDATE_PROFILE_PHOTO,
        type: "camera",
        section: "profile_photo",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Would you like to update your profile photo?\n\nNote: Please remove your mask when taking your photo";
                } else {
                    const { patient_first_name } = responses;
                    return `Would you like to update ${patient_first_name}'s profile photo?\n\nNote: Please remove your mask when taking your photo`;
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.UPDATE_MEDICAL_HISTORY
    }
];

export const patientHistory = [
    {
        id: PROMPT_IDS.UPDATE_MEDICAL_HISTORY,
        type: "option",
        section: "patient_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Would you like to update your medical history information now?\n\nNote: This could take between 5 - 10 minutes to complete. You could return to this website to complete this information at any time.";
                } else {
                    const { patient_first_name } = responses;
                    return `Would you like to update ${patient_first_name}'s medical history information now?\n\nNote: This could take between 5 - 10 minutes to complete. You could return to this website to complete this information at any time.`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ]
        },
        onResponse: (response, id, ctrl) => {
            if (response === "yes") {
                const { responses } = ctrl.state;
                const isEighteen = responses[PROMPT_IDS.IS_EIGHTEEN] === "yes";

                return isEighteen ? PROMPT_IDS.GENDER : PROMPT_IDS.ALLERGIES
            } else {
                return null;
            }

        }
    },
    {
        id: PROMPT_IDS.GENDER,
        type: "option",
        section: "patient_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please select your gender to help us capture the correct information for you";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select ${patient_first_name}'s gender to help us capture the correct information`;
                }
            },
            options: [
                { id: "female", text: "Female" },
                { id: "male", text: "Male" },
                { id: "other", text: "Other" },
                { id: "not_say", text: "I'd rather not say" }
            ]
        },
        onResponse: (response) => PROMPT_IDS.ALLERGIES
    }
];

export const allergies = [
    {
        id: PROMPT_IDS.ALLERGIES,
        type: "autocomplete",
        section: "allergies",
        config: {
            message: (responses, messageProps) => {
                const { for_who } = responses;
                const { type } = messageProps
                if (type === "dentistry") {
                    if (for_who === "myself") {
                        return "Please select your allergies, e.g. Latex\n\nNote: If not applicable, select 'None' and proceed";
                    } else {
                        const {patient_first_name} = responses;
                        return `Please select ${patient_first_name}'s allergies, e.g. Latex\n\nNote: If not applicable, select 'None' and proceed`;
                    }
                }

                if (for_who === "myself") {
                    return "Please select your allergies\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select ${patient_first_name}'s allergies\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            options: allergyList,
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Latex"
            },
            freeSolo: true,
            optional: true
        },
        onResponse: (response) => response === undefined || response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.MEDICAL_CONDITIONS : PROMPT_IDS.MORE_ALLERGIES
    },
    {
        id: PROMPT_IDS.MORE_ALLERGIES,
        type: "option",
        section: "allergies",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you have another allergy?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} have another allergy?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.ALLERGIES : PROMPT_IDS.ALLERGIES_NOTE
    },
    {
        id: "allergies_note",
        type: "text",
        section: "allergies",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your allergies";
                } else {
                    return "Please let us know if there's anything else that we should note about the allergies";
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.MEDICAL_CONDITIONS
    }
];

export const medicalCondition = [
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS,
        type: "autocomplete",
        section: "medical_conditions",
        config: {
            message: (responses, messageProps) => {
                const { for_who } = responses;
                const { type } = messageProps
                if (type === "dentistry") {
                    if (for_who === "myself") {
                        return "Please select your medical conditions, e.g. Hypertension\n\nNote: If not applicable, Select ‘None’ and proceed";
                    } else {
                        const {patient_first_name} = responses;
                        return `Please select ${patient_first_name}'s conditions, e.g. Hypertension\n\nNote: If not applicable, select 'None' and proceed`;
                    }
                }

                if (for_who === "myself") {
                    return "Please select your medical conditions\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select ${patient_first_name}'s medical conditions\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            options: medicalConditionsList,
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Hypertension"
            },
            freeSolo: true,
            optional: true
        },
        onResponse: (response) => response === undefined || response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.SURGICAL_PROCEDURES : PROMPT_IDS.MEDICAL_CONDITION_DATES
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITION_DATES,
        type: "date",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When were you diagnosed with this condition?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    return "When was this condition diagnosed?\n\nNote: Select the date nearest to which the patient is able to identify";
                }
            },
            max: new Date(),
            optional: true,
        },
        onResponse: (response) => PROMPT_IDS.MORE_MEDICAL_CONDITION
    },
    {
        id: PROMPT_IDS.MORE_MEDICAL_CONDITION,
        type: "option",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you have another medical condition?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} have another medical condition?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.MEDICAL_CONDITIONS : PROMPT_IDS.MEDICAL_CONDITIONS_NOTE
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS_NOTE,
        type: "text",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your medical conditions";
                } else {
                    return "Please let us know if there's anything else that we should note about the medical conditions";
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.SURGICAL_PROCEDURES
    }
];

export const surgicalProcedures = [
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURES,
        type: "autocomplete",
        section: "surgical_procedures",
        config: {
            message: (responses, messageProps) => {
                const { for_who } = responses;
                const { type } = messageProps;

                if (type === 'dentistry') {
                    if (for_who === "myself") {
                        return "Please select your surgical procedures\n\nNote: If not applicable, select 'None' and proceed";
                    } else {
                        const {patient_first_name} = responses;
                        return `Please select ${patient_first_name}'s surgical procedures\n\nNote: If not applicable, select 'None' and proceed`;
                    }
                }

                if (for_who === "myself") {
                    return "Please select the surgical procedures that you've had done\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select the surgical procedures that ${patient_first_name} had done\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            options: surgicalProceduresList,
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Appendectomy"
            },
            freeSolo: true,
            optional: true
        },
        onResponse: (response) => response === undefined || response?.trim()?.toLowerCase() === 'none' ? PROMPT_IDS.CHRONIC_MEDICATIONS : PROMPT_IDS.SURGICAL_PROCEDURE_DATES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_DATES,
        type: "date",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When did you have this procedure done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    return "When was this procedure done?\n\nSelect the date nearest to which the patient is able to identify";
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.MORE_SURGICAL_PROCEDURE
    },
    {
        id: PROMPT_IDS.MORE_SURGICAL_PROCEDURE,
        type: "option",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you had another surgical procedure done?";
                } else {
                    return "Were any other surgical procedures done?";
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.SURGICAL_PROCEDURES : PROMPT_IDS.SURGICAL_PROCEDURE_NOTES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_NOTES,
        type: "text",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should know about your surgical procedures";
                } else {
                    return "Please let us know if there's anything else that we should know about the surgical procedures";
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.CHRONIC_MEDICATIONS
    }
];

export const chronicMedication = (next = PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS) => [
    {
        id: PROMPT_IDS.CHRONIC_MEDICATIONS,
        type: "text",
        section: "chronic_medication",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please enter all your chronic medications\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please enter all ${patient_first_name}'s chronic medications\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Vicodin"
            },
            optional: true
        },
        onResponse: (response) => next
    }
];

export const familyHistory = [
    {
        id: PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS,
        type: "autocomplete",
        section: "family_history",
        config: {
            message: (responses, messageProps) => {
                const { for_who } = responses;
                const { type } = messageProps;

                if (type === "dentistry") {
                    if (for_who === "myself") {
                        return "When were you diagnosed with this condition?\n\nNote: Select the date nearest to which you are able to identify";
                    } else {
                        return "When was this condition diagnosed?\n\nNote: Select the date nearest to which the patient is able to identify";
                    }
                }

                if (for_who === "myself") {
                    return "Please select the medical conditions that are prevalent in your family\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select the medical conditions that are prevalent in ${patient_first_name}'s family\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            options: medicalConditionsList,
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Hypertension"
            },
            freeSolo: true,
            optional: true
        },
        onResponse: (response) => response === undefined ? PROMPT_IDS.VACCINES : PROMPT_IDS.MORE_FAMILY_MEDICAL_CONDITION
    },
    {
        id: PROMPT_IDS.MORE_FAMILY_MEDICAL_CONDITION,
        type: "option",
        section: "family_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Are there any other medical conditions that are prevalent in your family?";
                } else {
                    const { patient_first_name } = responses;
                    return `Are there any other medical conditions that are prevalent in ${patient_first_name}'s family?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS : PROMPT_IDS.FAMILY_MEDICAL_CONDITION_NOTES
    },
    {
        id: PROMPT_IDS.FAMILY_MEDICAL_CONDITION_NOTES,
        type: "text",
        section: "family_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should know about your family's medical history";
                } else {
                    return "Please let us know if there's anything else that we should know about the family's medical history";
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.VACCINES
    }
];

export const vaccinations = [
    {
        id: PROMPT_IDS.VACCINES,
        type: "autocomplete",
        section: "vaccinations",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please select the vaccines that you've taken\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select the vaccines that ${patient_first_name} has taken\n\nNote: If not applicable, click 'Skip'`;
                }
            },
            options: vaccinesList,
            placeholder: (responses, messageProps) => {
                const {type} = messageProps
                if (type === "dentistry")
                    return "Select"

                return "eg. Polio"
            },
            freeSolo: true,
            optional: true
        },
        onResponse: (response, id, ctrl) => {
            if (response === undefined) {
                const { responses } = ctrl.state;
                const isEighteen = responses[PROMPT_IDS.IS_EIGHTEEN] === "yes";

                return isEighteen ? PROMPT_IDS.SMOKER : null;
            } else {
                return PROMPT_IDS.VACCINE_DATES;
            }
        }
    },
    {
        id: "vaccine_dates",
        type: "date",
        section: "vaccinations",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When last did you take this vaccine?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const { patient_first_name } = responses;
                    return `When last did ${patient_first_name} take this vaccine?\n\nSelect the date nearest to which the patient is able to identify`;
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.MORE_VACCINE
    },
    {
        id: PROMPT_IDS.MORE_VACCINE,
        type: "option",
        section: "vaccinations",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you taken any other vaccines?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} taken any other vaccines?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response, id, ctrl) => {
            if (response === "yes") {
                return PROMPT_IDS.VACCINES;
            } else {
                const { responses } = ctrl.state;
                const isEighteen = responses[PROMPT_IDS.IS_EIGHTEEN] === "yes";

                return isEighteen ? PROMPT_IDS.SMOKER : null;
            }
        }
    }
];

export const lifestyleHistory = [
    {
        id: PROMPT_IDS.SMOKER,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you smoke?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} smoke?`;
                }
            },
            options: [
                { id: "no", text: "No" },
                { id: "less_than_5_per_day", text: "Yes, less than 5 cigarettes per day" },
                { id: "between_5_and_10_per_day", text: "Yes, between 5 and 10 cigarettes per day" },
                { id: "more_than_10_per_day", text: "Yes, more than 10 cigarettes per day" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.DRINK_ALCOHOL
    },
    {
        id: PROMPT_IDS.DRINK_ALCOHOL,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you drink alcohol?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} drink alcohol?`;
                }
            },
            options: [
                { id: "no", text: "No" },
                { id: "daily", text: "Yes, daily" },
                { id: "weekly", text: "Yes, weekly" },
                { id: "occassionally", text: "Yes, occassionally" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.EXERCISE
    },
    {
        id: PROMPT_IDS.EXERCISE,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you exercise?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} exercise?`;
                }
            },
            options: [
                { id: "no", text: "No" },
                { id: "daily", text: "Yes, daily" },
                { id: "2_5_times_per_week", text: "Yes, 2 - 5 times per week" },
                { id: "once_a_week", text: "Yes, once a week" }
            ],
            optional: true
        },
        onResponse: (response, id, ctrl) => {
            const { responses } = ctrl.state;
            const gender = responses[PROMPT_IDS.GENDER];
            if (gender === "female") {
                return PROMPT_IDS.HAVE_CHILDREN;
            } else if (gender === "male") {
                return PROMPT_IDS.LAST_PROSTATE_EXAM_DATE;
            } else {
                return null;
            }

        }
    }
];

export const adultFemaleMedicalHistory = [
    {
        id: PROMPT_IDS.HAVE_CHILDREN,
        type: "option",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                console.log(responses)
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Do you have any children?";
                } else {
                    const { patient_first_name } = responses;
                    return `Does ${patient_first_name} have any children?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.NUMBER_OF_CHILDREN : PROMPT_IDS.HAD_MISCARRIAGES
    },
    {
        id: PROMPT_IDS.NUMBER_OF_CHILDREN,
        type: "number",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "How many children do you have?";
                } else {
                    return "How many children does she have?";
                }
            },
            min: 0,
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.METHOD_OF_DELIVERY
    },
    {
        id: PROMPT_IDS.METHOD_OF_DELIVERY,
        type: "text",
        section: "adult_female_medical_history",
        config: {
            message: "Which method/s of delivery was used?",
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.DELIVERY_COMPLICATIONS
    },
    {
        id: PROMPT_IDS.DELIVERY_COMPLICATIONS,
        type: "text",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please let us know if you've had any complications with any of your deliveries";
                } else {
                    return "Please let us know if there were any complications with any of the deliveries";
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.HAD_MISCARRIAGES
    },
    {
        id: PROMPT_IDS.HAD_MISCARRIAGES,
        type: "option",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you had any miscarriages?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} had any miscarriages?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.HAD_ECTOPICS
    },
    {
        id: PROMPT_IDS.HAD_ECTOPICS,
        type: "option",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Have you had any ectopics?";
                } else {
                    const { patient_first_name } = responses;
                    return `Has ${patient_first_name} had any ectopics?`;
                }
            },
            options: [
                { id: "yes", text: "Yes" },
                { id: "no", text: "No" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.FAMILY_PLANNING_INTERVENTIONS
    },
    {
        id: PROMPT_IDS.FAMILY_PLANNING_INTERVENTIONS,
        type: "option",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "Please select if you are on any family planning interventions\n\nNote: If not applicable, click 'Skip'";
                } else {
                    const { patient_first_name } = responses;
                    return `Please select if ${patient_first_name} is on any family planning interventions`;
                }
            },
            options: [
                { id: "oral", text: "Oral" },
                { id: "injection", text: "Injection" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.LAST_PAP_SMEAR_DATE
    },
    {
        id: PROMPT_IDS.LAST_PAP_SMEAR_DATE,
        type: "date",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When did you have your last PAP smear done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const { patient_first_name } = responses;
                    return `When did ${patient_first_name} have her last PAP smear done?\n\nSelect the date nearest to which she is able to identify`;
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.LAST_MAMMOGRAM_DATE
    },
    {
        id: PROMPT_IDS.LAST_MAMMOGRAM_DATE,
        type: "date",
        section: "adult_female_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When did you have your last Mammogram done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const { patient_first_name } = responses;
                    return `When did ${patient_first_name} have her last Mammogram done?\n\nSelect the date nearest to which she is able to identify`;
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => null
    }
];

export const adultMaleMedicalHistory = [
    {
        id: PROMPT_IDS.LAST_PROSTATE_EXAM_DATE,
        type: "date",
        section: "adult_male_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When did you have your last prostate examination done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const { patient_first_name } = responses;
                    return `When did  ${patient_first_name} have his last prostate examination done?\n\nSelect the date nearest to what he is able to identify`;
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => response === undefined ? PROMPT_IDS.LAST_PSA_TEST_DATE : PROMPT_IDS.LAST_PROSTATE_EXAM_RESULT
    },
    {
        id: PROMPT_IDS.LAST_PROSTATE_EXAM_RESULT,
        type: "option",
        section: "adult_male_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "What was the result of your last examination?";
                } else {
                    return "What was the result of the last examination?";
                }
            },
            options: [
                { id: "normal", text: "Normal" },
                { id: "abnormal", text: "Abnormal" }
            ],
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.LAST_PSA_TEST_DATE
    },
    {
        id: PROMPT_IDS.LAST_PSA_TEST_DATE,
        type: "date",
        section: "adult_male_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "When did you have your last PSA test done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const { patient_first_name } = responses;
                    return `When did  ${patient_first_name} have his last PSA test done?\n\nSelect the date nearest to which he is able to identify`;
                }
            },
            max: new Date(),
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.LAST_PSA_TEST_RESULT
    },
    {
        id: PROMPT_IDS.LAST_PSA_TEST_RESULT,
        type: "option",
        section: "adult_male_medical_history",
        config: {
            message: (responses) => {
                const { for_who } = responses;

                if (for_who === "myself") {
                    return "What was the result of your last test?";
                } else {
                    return "What was the result of the last test?";
                }
            },
            options: [
                { id: "normal", text: "Normal" },
                { id: "abnormal", text: "Abnormal" }
            ],
            optional: true
        },
        onResponse: () => null
    }
];

export const homeAffairsGuide = [
    {
        id: PROMPT_IDS.HA_SELECT_LANGUAGE,
        type: "option",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                return "Please choose your preferred language"
            },
            options: Object.entries(languages).map(([id, text]) => ({id, text}))
        },
        onResponse: (response) => {
            return Object.keys(availableLanguages).includes(response) ? PROMPT_IDS.HA_SELECT_SERVICE : PROMPT_IDS.HA_DEFAULT_LANGUAGE
        }
    },
    {
        id: PROMPT_IDS.HA_DEFAULT_LANGUAGE,
        type: "option",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language } = responses
                return `The ${languages[ha_select_language]} language option is coming soon, for now please select one of the following`
            },
            options: Object.entries(availableLanguages).map(([id, text]) => ({id, text}))
        },
        onResponse: (response, id, ctrl) => {
            const { responses } = ctrl.state;
            responses[PROMPT_IDS.HA_SELECT_LANGUAGE] = response
            return PROMPT_IDS.HA_SELECT_SERVICE
        }
    },
    {
        id: PROMPT_IDS.HA_SELECT_SERVICE,
        type: "option",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language } = responses;

                if (ha_select_language === "en") {
                    return "Which services can we help you with today?";
                } else if (ha_select_language === "af")  {
                    return "Watter dienste kan ons u mee help vandag?";
                }
            },
            options: Object.entries(homeAffairsDocuments).map(([id, text]) => ({id, text})),
            optional: true
        },
        onResponse: (response) => response
    },
    {
        id: PROMPT_IDS.HA_LIST_REQUIREMENTS_BIRTH,
        type: "checkbox",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language, ha_select_service } = responses;

                if (ha_select_language === "en") {
                    return "Please remember to bring the following documents";
                } else if (ha_select_language === "af")  {
                    return "Onthou asseblief om die volgende dokumente saam te bring";
                }
            },
            options: Object.entries(documentRequirements[PROMPT_IDS.HA_LIST_REQUIREMENTS_BIRTH]).map(([id, text]) => ({id, text})),
            buttonText: 'Documents Ready'
        },
        onResponse: (response) => PROMPT_IDS.HA_LIST_LOCATIONS
    },
    {
        id: PROMPT_IDS.HA_LIST_REQUIREMENTS_DEATH,
        type: "checkbox",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language, ha_select_service } = responses;

                if (ha_select_language === "en") {
                    return "Please remember to bring the following documents";
                } else if (ha_select_language === "af")  {
                    return "Onthou asseblief om die volgende dokumente saam te bring";
                }
            },
            options: Object.entries(documentRequirements[PROMPT_IDS.HA_LIST_REQUIREMENTS_DEATH]).map(([id, text]) => ({id, text})),
            buttonText: 'Documents Ready'
        },
        onResponse: (response) => PROMPT_IDS.HA_LIST_LOCATIONS
    },
    {
        id: PROMPT_IDS.HA_LIST_REQUIREMENTS_MARRIAGE,
        type: "checkbox",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language, ha_select_service } = responses;

                if (ha_select_language === "en") {
                    return "Please remember to bring the following documents";
                } else if (ha_select_language === "af")  {
                    return "Onthou asseblief om die volgende dokumente saam te bring";
                }
            },
            options: Object.entries(documentRequirements[PROMPT_IDS.HA_LIST_REQUIREMENTS_MARRIAGE]).map(([id, text]) => ({id, text})),
            buttonText: 'Documents Ready'
        },
        onResponse: (response) => PROMPT_IDS.HA_LIST_LOCATIONS
    },
    {
        id: PROMPT_IDS.HA_LIST_REQUIREMENTS_ID,
        type: "checkbox",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language, ha_select_service } = responses;

                if (ha_select_language === "en") {
                    return "Please remember to bring the following documents";
                } else if (ha_select_language === "af")  {
                    return "Onthou asseblief om die volgende dokumente saam te bring";
                }
            },
            options: Object.entries(documentRequirements[PROMPT_IDS.HA_LIST_REQUIREMENTS_ID]).map(([id, text]) => ({id, text})),
            buttonText: 'Documents Ready'
        },
        onResponse: (response) => PROMPT_IDS.HA_LIST_LOCATIONS
    },
    {
        id: PROMPT_IDS.HA_LIST_REQUIREMENTS_PASSPORT,
        type: "checkbox",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language, ha_select_service } = responses;

                if (ha_select_language === "en") {
                    return "Please remember to bring the following documents";
                } else if (ha_select_language === "af")  {
                    return "Onthou asseblief om die volgende dokumente saam te bring";
                }
            },
            options: Object.entries(documentRequirements[PROMPT_IDS.HA_LIST_REQUIREMENTS_PASSPORT]).map(([id, text]) => ({id, text})),
            buttonText: 'Documents Ready'
        },
        onResponse: (response) => PROMPT_IDS.HA_LIST_LOCATIONS
    },
    {
        id: PROMPT_IDS.HA_LIST_LOCATIONS,
        type: "office",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language } = responses;

                if (ha_select_language === "en") {
                    return "Which Home Affairs office would you like to visit?";
                } else if (ha_select_language === "af")  {
                    return "Watter Binnelandse Sake kantoor sal u wil besoek?";
                }
            },
            options: Object.entries(homeAffairsOffices).map(([id, text]) => ({id, text, time: Math.floor(Math.random() * 55 + 5)})),
        },
        onResponse: (response) => PROMPT_IDS.HA_LINK_OFFICIAL_SITE
    },
    {
        id: PROMPT_IDS.HA_LINK_OFFICIAL_SITE,
        type: "link",
        section: "home_affairs_guide",
        canGoBack: false,
        config: {
            message: (responses) => {
                const { ha_select_language } = responses;

                if (ha_select_language === "en") {
                    return "Please make use of our online booking service to help speed up your application";
                } else if (ha_select_language === "af")  {
                    return "Maak asseblief gebruik van ons aanlyn bespreking dienste om jou aansoek te bespoedig";
                }
            },
            options: [{url:'https://services.dha.gov.za/#/authenticate/identity', label: 'Book an appointment'}],
        },
        onResponse: (response) => PROMPT_IDS.HA_END
    },
    {
        id: PROMPT_IDS.HA_END,
        type: "option",
        section: "home_affairs_guide",
        config: {
            message: (responses) => {
                const { ha_select_language } = responses;

                if (ha_select_language === "en") {
                    return "Thank you, have great day!";
                } else if (ha_select_language === "af")  {
                    return "Dankie, geniet u dag verder!";
                }
            },
            options: [],
        },
        onResponse: (response) => null
    },
];

const prompts = [
    // ...userValidation,
    // ...preVisitAssessment,
    // ...profilePhoto,
    // ...patientHistory,
    // ...allergies,
    // ...medicalCondition,
    // ...surgicalProcedures,
    // ...chronicMedication(PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS),
    // ...familyHistory,
    // ...vaccinations,
    // ...lifestyleHistory,
    // ...adultFemaleMedicalHistory,
    // ...adultMaleMedicalHistory
    ...homeAffairsGuide
];


export default prompts;