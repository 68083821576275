export const languages = {
    en: "English",
    af: "Afrikaans",
    nd: "isiNdebele (coming soon)",
    xh: "isiXhosa (coming soon)",
    zu: "isiZulu (coming soon)",
    // ss: "Sesotho",
    // sp: "Sepedi",
    // st: "Setswana",
    // si: "siSwati",
    // tv: "Tshivenda",
    // xt: "Xitsonga"
}

export const availableLanguages = {
    en: "English",
    af: "Afrikaans"
}

