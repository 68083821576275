import React from "react";

const Background = ({showAngled=true, className}) => {
    return (
        <svg className={className} width="962" height="488" viewBox="0 0 962 488" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none meet">
            <defs>
                <linearGradient x1="-1000.836%" y1="79.608%" x2="636.133%" y2="79.608%" id="b">
                    <stop stopColor="#C0C2BE" offset="0%"/>
                    <stop stopColor="#D4D7D0" offset="100%"/>
                </linearGradient>
                <linearGradient x1="12550.725%" y1="-44906.57%" x2="-49005.314%" y2="72861.44%" id="e">
                    <stop stopColor="#CFC6C4" offset="0%"/>
                    <stop stopColor="#9CB0BB" offset="100%"/>
                </linearGradient>
                <path d="m12.511 37 .089-.492c3.775-2.841 11.556-8.82 15.135-12.08 5.747-5.24 11.019-11.074 14.687-17.747A20.743 20.743 0 0 0 44.574.725L45 0a20.91 20.91 0 0 1-2.317 6.91c-3.668 6.672-8.94 12.507-14.687 17.746C24.301 28.022 16.129 34.282 12.511 37m-6.583-2.28A432.243 432.243 0 0 1 0 30.107l.042.009a430.62 430.62 0 0 0 5.893 4.58l-.007.024" id="a"/>
                <path d="M0 .809a9.858 9.858 0 0 0 1-.618C.71.425.362.631 0 .809" id="d"/>
            </defs>
            <g transform="translate(.554 .242)" fill="none" fillRule="evenodd">
                <path d="M0 370.195c35.61 35.85 94.137 54.158 181.46 55.379 40.617.568 70.72-.158 111.032-2.443 29.627-1.679 74.855-8.598 102.848-13.232 110.954-18.368 206.186-41.438 308.382-66.841C776.247 325.03 862.964 291.44 961 291.44V14.758H0v355.437z" fill="#E9F5FF"/>
                <path d="M3.345 278.758c127.225 21.267 240.956 11.934 341.193-28C494.895 190.858 571.928 67.424 961 71.241" stroke="#FFF"/>
                <line x1="672.946" y1="64.258" x2="672.946" y2="64.258" style={{stroke:'#007749',strokeLinecap:'round',strokeWidth:'1.75vh',vectorEffect:'non-scaling-stroke'}} />
                <line x1="258" y1="436" x2="258" y2="436" style={{stroke:'#007749',strokeLinecap:'round',strokeWidth:'1.75vh',vectorEffect:'non-scaling-stroke'}} />
                <line x1="28" y1="242" x2="28" y2="242" style={{stroke:'#007749',strokeLinecap:'round',strokeWidth:'1.75vh',vectorEffect:'non-scaling-stroke'}} />
                {showAngled && <rect fill="#0071cb" x="884" y="0" width="5" height="24"/>}
                {showAngled && <path fill="#F28607" d="M134.946 29.017 136.688 0l22.516 13z"/>}
                <line x1="753.446" y1="329.758" x2="753.446" y2="329.758" style={{stroke:'#007749',strokeLinecap:'round',strokeWidth:'1.75vh',vectorEffect:'non-scaling-stroke'}} />
            </g>
        </svg>
    )
}

export default Background