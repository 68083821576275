const birthCertificateRequirements = {
    'doc1': "DHA 24/LRB (notice of birth)",
    'doc2': "Children born at a health facilities: DHA 24/PB (Proof of birth)",
    'doc3': "Children born at home: DHA 24PBA (Proof of Birth Affidavit)",
    'doc4': "DHA 288 /A (Affidavit giving reasons for LRB)",
    // 'doc5': "Biometrics (palm, foot or fingerprint) of the child to be registered",
    // 'doc6': "Fingerprints of parent/s",
    'doc7': "ID/Passport of parent/s",
}

const deathCertificateRequirements = {
    'doc1': "Form BI-1663 (Notification of death/still-birth)",
    'doc2': "The person reporting the death",
    'doc3': "A medical practitioner (where a medical practitioner is not available to complete this form, in rural areas for example, a traditional leader may complete the form)",
    'doc4': "A Home Affairs official (where an official from the Department of Home Affairs is not available then a member of the SA Police Services may be approached to complete the form)",
}

const marriageCertificateRequirements = {
    'doc1': "Identity documents(for each person getting married)",
    'doc2': "If a foreign national is marrying a South African citizen, they should both present their valid passports as well as well as a completed BI-31 Form (Declaration for the Purpose of Marriage, Letter of no impediment)",
    'doc3': "If the wedding is for a minor (a person under the age of 18 years), the written consent of both parents/ legal guardian or the Commissioner of Child Welfare or a judge should be submitted on Form DHA-32 as well. If the minors getting married are under the ages of 18 for boys or 15 for girls, the written consent from the Minister of Home Affairs will also be required",
    'doc4': "If any of the persons getting married are divorced, then the final decree of divorce should be furnished",
    'doc5': "If any of the persons getting married are widowed, the deceased spouse’s death certificate must be submitted.",
}

const identityDocumentRequirements = {
    'doc1': "Form BI-9, completed in black ink",
    'doc2': "A certified copy of your Birth certificate or reference book or a copy of the old Transkei, Bophutatswana, Venda or Ciskei homelands identity or travel documents",
    'doc3': "Two identical, colour photographs (NOT needed at smartcard offices as ID images are captured digitally however colour photographs are still needed for temporary IDs)",
}

const passportDocumentRequirements = {
    'doc1': "A duly completed passport application Form DHA-73",
    'doc2': "Your original identity document and a copy",
    'doc3': "A letter on the official letterhead and signed by the head of institution or his or her delegate confirming that the passport is needed for an official trip and that you are employed by the institution\n",
    'doc4': "Two colour  photographs that comply with the Passport and ID Photograph Specifications (NOT needed at smartcard offices as ID images are captured digitally)",
    'doc5': "Submit your existing valid official passport or if lost or stolen a lost passport report (DHA-335) as well as confirmation that the loss was reported to the police",
}

export const documentRequirements = {
    'ha_list_requirements_birth': birthCertificateRequirements,
    'ha_list_requirements_death': deathCertificateRequirements,
    'ha_list_requirements_marriage': marriageCertificateRequirements,
    'ha_list_requirements_id': identityDocumentRequirements,
    'ha_list_requirements_passport': passportDocumentRequirements,
}