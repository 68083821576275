import React from "react"
import Button from "@mui/material/Button";
import feedback from "../../assets/feedback.webm"

const LinkComponent = ({prompt, onResponse, responses}) => {
    const [checked, setChecked] = React.useState([])

    const updateChecked = (event, id) => {
        if (event.target.checked && !checked.includes(id)) setChecked([...checked, id].sort())
        else if (!event.target.checked && checked.includes(id)) setChecked(checked.filter(x => x !== id))
    }

    const openLink = (url) => {
        let win = window.open(url, '_blank');
        win.focus();
        onResponse();
    }

    return <div>
        {prompt.config.options?.map(({url, label}, index) =>
            <Button
                key={index}
                variant="outlined"
                onClick={() => openLink(url)}
                sx={{width: '100%', marginBottom: '1em'}}>
                {label ?? url}
            </Button>)}
        <video controls playsInline style={{
            margin: 'auto',
            borderRadius: 12,
            width: 'clamp(270px, 100%, 600px)',
            display: 'block',
            aspectRatio: '16 / 9'
        }} alt={"Video of Leon Schreiber explaining the platform"}>
            <source src={feedback} type="video/webm"/>
            Your browser does not support the video tag.
        </video>
    </div>
}

export default LinkComponent