import React from "react"
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const CheckboxComponent = ({prompt, onResponse, responses}) => {
    const [checked, setChecked] = React.useState([])

    const updateChecked = (event, id) => {
        if (event.target.checked && !checked.includes(id)) setChecked([...checked, id].sort())
        else if (!event.target.checked && checked.includes(id)) setChecked(checked.filter(x => x !== id))
    }

    return <div>
        <FormGroup>
            {prompt.config.options?.map(({id, text}) => <FormControlLabel
                key={id}
                label={text}
                control={<Checkbox
                    onChange={(event) => updateChecked(event, id)}
                    sx={{
                        alignSelf: 'start',
                        color: "#02bf76",
                        '&.Mui-checked': {
                            color: "#007749",
                        }
                    }}
                />}
            />)}
        </FormGroup>
        <Button
            variant="outlined"
            onClick={onResponse}
            sx={{width: '100%', marginTop: '1em'}}
            disabled={checked.length !== (prompt.config.options?.length ?? 0)}>
            {prompt.config.buttonText ?? 'Continue'}
        </Button>
    </div>
}

export default CheckboxComponent