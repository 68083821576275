import {backdropStyles} from "./styles";
import people from "../assets/people.svg";
import {GP_WORKFLOW} from "../workflows/workflows";
import Background from "../components/Background";
import React from "react";


const GetStartedScreen = ({show, setWorkflow}) => {
    const styles = backdropStyles();
    return (
        show &&
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>Welcome to the future of citizen services</div>
                <div className={styles.subtitle}>You can gather key information ahead of your visit to Home Affairs,
                    right here!
                </div>
                <img alt="" src={people} className={styles.people}/>
                <button className={`${styles.button} ${styles.button_one}`}
                        onClick={() => setWorkflow(GP_WORKFLOW)}>Let's Go
                </button>
            </div>
            <Background showAngled={false} className={styles.background}/>
        </div>
    )
}

export default GetStartedScreen